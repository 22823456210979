@import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

.ag-theme-alpine {
  --ag-border-radius: none;
  --ag-side-bar-panel-width: 364px;
  --ag-list-item-height: 32px;
  --ag-font-family: 'AktivGrotesk', sans-serif;
  --ag-widget-vertical-spacing: 16px;
  --ag-font-size: 14px;

  .ag-header-cell:not(.select-all) {
    @apply px-[20px];
  }

  .ag-filter-list-panel {
    @apply pb-[1px];
  }

  .ag-filter-toolpanel {
    padding-top: 60px;
  }
  /* Search bar */
  .ag-filter-toolpanel-search {
    padding-left: 0 !important;
    @apply h-11 mb-[16px];

    // Fixed search panel
    position: absolute;
    padding-top: 32px;
    top: 1px;
    z-index: 1;
    background: #fff;
    width: calc(
      100% - 48px - 30px - 1px
    ); // 100% - horizontalPadding - column/filter tab width - column/filter tab border
  }

  .ag-filter-toolpanel-search-input {
    margin: 0 !important;
    @apply h-full;
  }

  .ag-filter-toolpanel-search-input .ag-text-field-input {
    @apply h-11;
  }

  .ag-tool-panel-wrapper {
    border-top: var(--ag-borders) var(--ag-border-color);
    @apply py-4 px-6 bg-white;
  }

  .ag-filter-toolpanel-group-level-0-header {
    @apply py-[28px];
  }

  .ag-filter-toolpanel-group-wrapper {
    @apply mb-4;
  }

  .ag-filter-toolpanel-group-level-0 {
    @apply border border-[rgba(0,0,0,0.2)];
  }

  .ag-ltr .ag-filter-toolpanel-group-container {
    @apply pl-0;
  }

  .ag-ltr .ag-filter-toolpanel-instance-filter {
    @apply border-x-0 m-0 bg-white;
  }

  input[class^='ag-']:not([type]),
  input[class^='ag-'][type='text'],
  input[class^='ag-'][type='number'],
  input[class^='ag-'][type='tel'],
  input[class^='ag-'][type='date'],
  input[class^='ag-'][type='datetime-local'],
  textarea[class^='ag-'] {
    min-height: var(--ag-list-item-height);
    padding-left: 8px !important;
  }

  /* Dropdown */
  .ag-picker-field-wrapper {
    @apply rounded-none font-bold;
  }

  .ag-picker-field-display {
    @apply px-2 m-0;
  }

  .ag-picker-field-icon {
    @apply mr-2;
  }

  .ag-select-list-item {
    @apply px-2 py-5 cursor-pointer;

    &.ag-active-item {
      @apply bg-whiteWildSand;
    }
  }

  /* Grid Table */
  .ag-header-cell-label {
    justify-content: start !important;
  }
}

/* Fonts */
@import './assets/fonts/index.css';

:root {
  --content-wrapper-width: 1920px;
}

* {
  box-sizing: border-box;
}

html {
  min-width: 320px;
  background-color: #f9fafb;
  font-size: 16px;
  line-height: 1.3;
  font-family: 'AktivGrotesk', sans-serif;
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  height: 100%;
  min-height: 100%;
}

#root {
  height: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.Toastify__close-button {
  opacity: 1 !important;
}

@layer base {
  * {
    font-family: 'AktivGrotesk', sans-serif;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @page {
    size: A4 landscape;
  }
}
@media print {
  * {
    /* Enable background graphics */
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
  }

  html {
    margin: 0;
  }

  body {
    margin: 0cm;
    height: auto;
    min-height: 0;
  }

  .dropdown-items {
    @apply hidden;
  }

  .body-row * {
    /* To stay above the watermark */
    @apply z-20;
  }

  table,
  tbody {
    page-break-inside: auto;
    page-break-before: auto;
    page-break-after: auto;
    break-inside: auto;
    break-before: auto;
    break-after: auto;
  }

  thead,
  tr,
  td {
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-before: auto;
    break-inside: avoid;
    break-before: auto;
    break-after: auto;
  }
}

.content-wrapper {
  @apply px-0 sm:px-10 min-w-[200px] max-w-[var(--content-wrapper-width)] mx-auto;
}

header .content-wrapper,
.content-wrapper.header {
  @apply px-4 md:px-10 min-w-[200px] max-w-[var(--content-wrapper-width)] mx-auto;
}
/* Wrapper for main content to be full page
regardless of content-wrapper full width.
Places main z above headers */
.main-wrapper {
  @apply w-full h-full relative bg-white;
}
.form-row {
  @apply grid gap-4 lg:ml-24;
}

.form-row.full-width {
  @apply lg:ml-0 w-full;
}

.form-row.two-column {
  @apply grid-cols-2;
}

.fixed-first-column-with-labels {
  @apply grid grid-cols-[100px_1fr] md:grid-cols-[100px_1fr_100px_1fr] lg:grid-cols-[200px_1fr_160px_1fr] gap-4 lg:gap-5;
}

.standard-label {
  @apply inline-block text-sm font-medium whitespace-nowrap pr-2 mb-[2px];
}

.form-label {
  @apply text-sm font-medium;
}

.totals-label {
  @apply text-lg font-medium text-right;
}

.eyebrow-label {
  @apply text-[10px] text-greyTapa;
}

.form-field-wrapper {
  @apply relative w-full flex flex-row min-h-[2.5rem];
}

.missing-data {
  @apply border-solid border-2 border-merlot;
}

.center-content {
  @apply flex justify-center items-center;
}

.info-container {
  @apply text-sm font-bold;
}

.info-section {
  @apply font-medium mt-1 !ml-0 flex justify-between;
}

.info-section div,
.info-section a,
.info-section span {
  @apply ml-0 mr-[100px];
}

.external-link {
  @apply text-black no-underline hover:underline hover:text-curiousBlue;
}

.editor-container {
  @apply bg-white border top-0;
}

.force-z {
  @apply !z-50;
}

.highlighted-row {
  @apply bg-cinnabar !text-greyCod;
}

.radius-row {
  @apply !z-50 !overflow-visible;
}

.white-path {
  filter: brightness(0) invert(1);
}

.grid-height {
  @apply h-[calc(100vh-200px)] pb-10;
}

.ant-picker-separator .anticon {
  @apply w-4 h-4 align-top;
}

.ag-pinned-right-cols-container,
.ag-pinned-right-header,
.ag-pinned-right-header .ag-header-row-column,
.ag-pinned-right-header .ag-header-row-column .ag-header-cell {
  @apply !w-11 !min-w-[44px] !max-w-[44px] md:!w-28 md:!min-w-[112px] md:!max-w-[112px];
}

.ag-pinned-right-header
  .ag-header-row:not(:first-child)
  .ag-header-cell:not(.ag-header-span-height.ag-header-span-total) {
  @apply !w-11 !min-w-[44px] !max-w-[44px] md:!w-28 md:!min-w-[112px] md:!max-w-[112px];
}

.ag-pinned-right-header
  .ag-header-row:not(:first-child)
  .ag-header-cell:not(.ag-header-span-height.ag-header-span-total)
  .ag-header-cell-label,
.ag-pinned-right-header .ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper {
  @apply hidden md:flex;
}

.ag-pinned-right-cols-container
  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
  @apply !w-11 !min-w-[44px] !max-w-[44px] md:!w-28 md:!min-w-[112px] md:!max-w-[112px] !p-0;
}

.calendar-unavailable-pattern {
  background-size: 8px 8px;
  background-image: repeating-linear-gradient(45deg, #00000026 0, #00000026 1px, transparent 0, transparent 50%);
}

/* Transparent Scrollbar */
.no-scrollbar::-webkit-scrollbar {
  background: transparent;
  width: 6px;
}
.no-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: grey;
  margin: 10px 0;
}

.input-select .input-select__control {
  @apply max-sm:shadow-none;
}

.horizontal-calcultation {
  display: inline-block;
  width: 50%;
}

.text-description {
  display: block;
}

.add-button {
  font-family: 'Aktiv Grotesk';
  font-size: 14px;
  font-weight: bold;
}

.drawer-overlay {
  background-color: #44444463;
}

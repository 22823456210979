@font-face {
  font-family: 'AktivGrotesk';
  src: url('./AktivGrotesk/subset-AktivGrotesk-Light.eot');
  src:
    url('./AktivGrotesk/subset-AktivGrotesk-Light.eot?#iefix') format('embedded-opentype'),
    url('./AktivGrotesk/subset-AktivGrotesk-Light.woff2') format('woff2'),
    url('./AktivGrotesk/subset-AktivGrotesk-Light.woff') format('woff'),
    url('./AktivGrotesk/subset-AktivGrotesk-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./AktivGrotesk/subset-AktivGrotesk-Regular.eot');
  src:
    url('./AktivGrotesk/subset-AktivGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
    url('./AktivGrotesk/subset-AktivGrotesk-Regular.woff2') format('woff2'),
    url('./AktivGrotesk/subset-AktivGrotesk-Regular.woff') format('woff'),
    url('./AktivGrotesk/subset-AktivGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./AktivGrotesk/subset-AktivGrotesk-Medium.eot');
  src:
    url('./AktivGrotesk/subset-AktivGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
    url('./AktivGrotesk/subset-AktivGrotesk-Medium.woff2') format('woff2'),
    url('./AktivGrotesk/subset-AktivGrotesk-Medium.woff') format('woff'),
    url('./AktivGrotesk/subset-AktivGrotesk-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./AktivGrotesk/subset-AktivGrotesk-Bold.eot');
  src:
    url('./AktivGrotesk/subset-AktivGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
    url('./AktivGrotesk/subset-AktivGrotesk-Bold.woff2') format('woff2'),
    url('./AktivGrotesk/subset-AktivGrotesk-Bold.woff') format('woff'),
    url('./AktivGrotesk/subset-AktivGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aktiv Grotesk Ex XBold';
  src: url('./AktivGrotesk/subset-AktivGroteskEx-XBold.eot');
  src:
    url('./AktivGrotesk/subset-AktivGroteskEx-XBold.eot?#iefix') format('embedded-opentype'),
    url('./AktivGrotesk/subset-AktivGroteskEx-XBold.woff2') format('woff2'),
    url('./AktivGrotesk/subset-AktivGroteskEx-XBold.woff') format('woff'),
    url('./AktivGrotesk/subset-AktivGroteskEx-XBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: 'Aktiv Grotesk Ex XBold';
  src: url('./AktivGrotesk/subset-AktivGroteskEx-XBold.eot');
  src: url('./AktivGrotesk/subset-AktivGroteskEx-XBold.eot?#iefix') format('embedded-opentype'),
    url('./AktivGrotesk/subset-AktivGroteskEx-XBold.woff2') format('woff2'),
    url('./AktivGrotesk/subset-AktivGroteskEx-XBold.woff') format('woff'),
    url('./AktivGrotesk/subset-AktivGroteskEx-XBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: 'Aktiv Grotesk Condensed';
  src: url('./AktivGrotesk/condensed/AktivGroteskCd-Medium.otf');
  src:
    url('./AktivGrotesk/condensed/AktivGroteskCd-Medium.otf?#iefix') format('opentype'),
    url('./AktivGrotesk/condensed/AktivGroteskCd-Medium.woff2') format('woff2'),
    url('./AktivGrotesk/condensed/AktivGroteskCd-Medium.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aktiv-G';
  src: url('./AktivGrotesk-Thin.otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv-G';
  src: url('./AktivGrotesk-Hairline.otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv-G';
  src: url('./AktivGrotesk-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv-G';
  src: url('./AktivGrotesk-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv-G';
  src: url('./AktivGrotesk-Medium.otf');
  font-weight: 500;
  font-style: auto;
}

@font-face {
  font-family: 'Aktiv-G';
  src: url('./AktivGrotesk-Bold.otf');
  font-weight: 700;
  font-style: auto;
}

@font-face {
  font-family: 'Aktiv-G';
  src: url('./AktivGrotesk-XBold.otf');
  font-weight: 800;
  font-style: auto;
}
.btn-primary {
  @apply bg-white text-black h-10 px-5 py-2 text-sm font-extended no-underline hover:underline;
}

.btn-secondary {
  @apply border border-greyCod bg-greyCod text-white font-extended px-5 py-2 no-underline hover:underline;
}

.btn-stroke {
  @apply border border-black text-black h-10 px-5 py-2 text-sm font-extended no-underline hover:underline;
}

/*
  * Custom styles for the FormSelect component
*/
.input-select__value-container.input-select__value-container--is-multi.input-select__value-container--has-value.css-lm6wou {
  padding: 7px 0 10px !important;
}

/*
  * Custom styles for the FormSelect component
*/
.input-select__value-container.input-select__value-container--is-multi.input-select__value-container--has-value.css-lm6wou {
  padding: 7px 0 10px !important;
}

.input-select__value-container.input-select__value-container--has-value.css-1kmaqds {
  padding: 10px 0 !important;
}

.tab-item:not(.tab-item-selected) .badge {
  color: #b4b4b4 !important;
  background-color: #f2f2ef !important;
}

@media (max-width: 767px) {
  .avails-mobile {
    .input-text,
    .input-select.sm,
    .input-text.xs,
    select {
      font-size: 16px !important;
    }
  }

  .avails-filter-container {
    .input-select__value-container.input-select__value-container--is-multi {
      font-size: 16px !important;
    }
  }
}

@import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

.ag-theme-alpine {
  .ag-root-wrapper {
    border: 0;
    border-image-width: 0;
  }
  .ag-side-buttons {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .ag-paging-panel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      padding-top: 10px;
    }
  }

  .ag-header {
    background-color: #f5f5f5;
    color: #141414;
    font-size: 12px;
    line-height: 15px;
    border: none;
  }
  .ag-pinned-right-header,
  .ag-pinned-right-cols-container {
    background: #f2f2ef !important;
    border: 0;
  }

  .ag-header-cell {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 0 !important;
  }
  .ag-header-cell-label {
    justify-content: center;
  }

  .ag-header-cell-text {
    padding: 2px;
    font-weight: bold;
  }

  .ag-header-cell-resize::after {
    top: 0;
    height: 100%;
    background-color: white;
  }

  .ag-pinned-left-header,
  .ag-pinned-left-cols-container {
    background: #f2f2ef;
    border: 0;
    border-right: 2px solid white;
    border-image-width: 0;
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell),
  .ag-row-position-absolute .ag-cell {
    border-right: 0;
    border-image-width: 0;
  }

  .ag-pinned-right-header,
  .ag-pinned-right-cols-container {
    background: #ffffff;
    box-shadow: -3px 0px 8px -2px rgba(0, 0, 0, 0.25);
  }

  .ag-row-odd,
  .ag-row-even {
    border-bottom: 0.1px solid #dfdfdb;
  }

  .ag-row-odd {
    background: #f2f2ef;
  }

  .ag-cell {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    line-height: 15px;
    padding: 5px;
    // border-left: 1px solid white;

    & > div {
      height: 100%;
      width: 100%;
    }
  }

  .ag-center-cols-clipper .ag-cell {
    border-right: 2px solid white;
  }

  .ag-cell.ag-cell-first-right-pinned {
    border: 0 !important;
  }

  .ag-cell-focus {
    border: 0.5px solid #2187b9;
  }

  .ag-cell-focus.month-break {
    border: none !important;
    outline: none;
  }

  .ag-row {
    z-index: 1;
    border: 0;
    &.ag-row-focus {
      z-index: 2;
    }
  }

  .drag-row {
    overflow: unset !important;
  }

  .drag-row .ag-row-drag {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}
